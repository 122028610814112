<template>
  <div class="tagger-list">
    <ai-breadcrumb />
    <div class="new-button">
      <router-link to="/services/tagger/new">
        <el-button icon="el-icon-plus" size="small" type="primary">{{
          $t("common.new")
        }}</el-button>
      </router-link>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        prop="desc"
        :label="$t('tagger.desc')"
        min-width="120"
      ></el-table-column>
      <el-table-column :label="$t('tagger.status')" min-width="60">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.status | statusFilter"
            effect="dark"
            size="small"
            >{{
              scope.row.status === "waiting"
                ? $t("tagger.waiting")
                : scope.row.status === "running"
                ? $t("tagger.running")
                : scope.row.status === "done"
                ? $t("tagger.done")
                : scope.row.status === "abort"
                ? $t("tagger.abort")
                : scope.row.status === "error"
                ? $t("tagger.error")
                : scope.row.status
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('tagger.execAt')" min-width="135">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.execAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('tagger.finishAt')" min-width="135">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.finishAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="imageCount"
        :label="$t('tagger.imageCount')"
        min-width="80"
      ></el-table-column>
      <el-table-column :label="$t('common.detail')" min-width="60">
        <template slot-scope="scope">
          <router-link :to="'/services/tagger/' + scope.row.id">
            <el-button
              type="primary"
              icon="el-icon-more"
              circle
              size="small"
            ></el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.delete')" min-width="60">
        <template slot-scope="scope">
          <el-popconfirm
            :title="$t('tagger.deleteTask')"
            @confirm="deleteTask(scope.row.id)"
          >
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="small"
              slot="reference"
            ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column :label="$t('tagger.restart')" min-width="60">
        <template slot-scope="scope">
          <el-button
            type="warning"
            icon="el-icon-refresh-left"
            circle
            size="small"
            @click="restart(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="fetchData"
    />
  </div>
</template>

<script>
import { fetch, restart, deleteTask } from "@/api/task";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Pagination from "@/components/Pagination";

export default {
  components: { AiBreadcrumb, Pagination },
  data() {
    return {
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
    };
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        waiting: "warning",
        running: "",
        done: "success",
        abort: "info",
        error: "danger",
      };
      return statusMap[status];
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      fetch({ execType: "tagger", ...this.listQuery }).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      });
    },
    restart(id) {
      restart(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: this.$t("tagger.restartSucc"),
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    deleteTask(id) {
      deleteTask(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: this.$t("common.deleteSucc"),
            type: "success",
          });
          this.fetchData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tagger-list {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }
}
</style>